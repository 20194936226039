h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-family: $headings-font;
    font-weight: bold;
    color:#222;
    line-height: 1.2;

    a{
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        color:inherit;
        &:hover{color:$color-blue;}
    }
}

h1{

    position: relative;
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 1.1;
    @include maxWidth(1366px){
        font-size: 36px;
    }
    @include maxWidth(1024px){
        font-size: 32px;
    }

    @include maxWidth(768px){
        font-size: 28px;
    }

    @include maxWidth(480px){
        font-size: 24px;
    }
}

h2{
    font-size: 20px;
    margin-bottom:16px;
    line-height: 1.1;
    @include maxWidth(1024px){
        font-size: 24px;
    }

    @include maxWidth(768px){
       // font-size: 20px;
    }

    @include maxWidth(480px){
        font-size: 20px;
    }
}

p + h2, ul + h2{margin-top: 45px;}

h3{
    font-size: 18px;
    margin-bottom:20px;
    @include maxWidth(1024px){
        font-size: 18px;
    }


    //@include maxWidth(480px){
    //    font-size: 15px;
    //}
}

h4{
    font-size: 17px;
    margin-bottom: 20px;

    @include maxWidth(768px){
        font-size: 16px;
    }

}
h2+h4{margin-top: -25px;}

h5{
    font-size: 14px;
    color:#535353;
    margin-bottom: 20px;
    @include maxWidth(1024px){
        font-size: 14px;
    }

    @include maxWidth(768px){
        font-size: 13px;
    }

    @include maxWidth(680px){
        font-size: 13px;
    }
}

h6{
    font-size: 14px;
    font-weight: normal;
    color:$paragraph-color;
}

p {
    line-height: 1.71;
    margin: 0 0 30px 0;
    font-family: $primary-font;
    font-weight: 400;
    font-size: 14px;
    color:$paragraph-color;
    max-width: 900px;

    @include maxWidth(768px){
        font-size: 13px;
    }
}


a {
    font-size: inherit;
    color: $color-blue;
    font-family:inherit;
    text-decoration: none;
    &:hover {
        color: $color-grayDark;
    }
}

