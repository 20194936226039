$sidebar-bg-color: #0c1e35;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(#0c1e35, 0.95);
$sidebar-color: #b3b8d4;
$highlight-color: #dee2ec;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;


body {
  margin: 0;
  height: 100vh;
  color: #353535;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: relative;

  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    padding: 20px;
    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 0;
    }
  }

  main {
    padding: 24px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

  }

  .pro-sidebar{
    .pro-icon-wrapper{border-radius: 50%;}
  }



  @media (max-width: 767.98px) {
    overflow-x: hidden;

  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

  }
}


