// FONTS
$font-weight-normal: normal;
$font-weight-bold: 700;
$default-font-size: 14px;
$default-line-height: 1.2;

$primary-font: 'Mulish', sans-serif;
$primary-font-weight: $font-weight-normal;

$headings-font: 'Mulish', sans-serif;
$headings-font-weight: 700;


// COLORS

$color-blue: #007EFF;
$color-blueGreen: #06e4ef;

$color-gray: #606060;

$color-black: #000000;
$color-grayDark: #1F1F1F;

$color-grayLight: #E8E8E8;
$color-white: #fff;

$color-green: #6fd56c;
$color-red: #ff0000;

// TEXT COLORS FOR P
$paragraph-color: #1D1D1F;


//BORDERS
$default-border: 1px solid #CBCBCB;;
$border-radius: 8px;

//INPUT
$input-font-color: $paragraph-color;
$input-background-color: transparent;
$input-font-size: 14px;
$input-font-weight: $font-weight-normal;

$input-submit-font-size: 13px;
$input-submit-font-color: $paragraph-color;
$input-submit-font-color-hover: #ffffff;
$input-submit-font-weight: $font-weight-normal;


//LABEL
$label-font-color:  #717171;
$label-font-size: 14px;
$label-font-weight: normal;

//SELECT 2
$select-font-color: $paragraph-color;
$select-background-color: #ffffff;