body{
    font-family: $primary-font;
    font-size: 14px;
    background: #fcfcfc;
}

img{max-width: 100%; height: auto;}

textarea{
    height: 200px !important;
}
.navbar{
    background: $color-grayLight !important;
    .navbar-brand{
        a{color:#222; text-decoration: none;}
    }
}

.pro-sidebar-header{
    margin-top: 40px;
    padding: 20px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a{color:$color-blueGreen;}
}


.container-main{
    margin-top: 24px;
    padding:0 16px;
}

.list-group{
    a{color:#222; text-decoration: none;
        &:hover{color:$color-blue;}
    }
}
.edit-btn{
    margin-right: 10px;
}

.login-form{
    margin-top: 10rem;
    padding: 24px;
    background: #fff;
    box-shadow: 0px 5px 10px rgba(37, 40, 43, 0.22);
    border-radius: $border-radius;
    p:last-of-type{margin-bottom: 0}
}

.align-right{text-align: right;}

.circle-icon{margin-left: 10px;}
.circle-green{color:$color-green;}
.circle-red{color:$color-red;}

.sidebar-btn-wrapper >div{
    display: flex; align-items: center; cursor: pointer;
    .logout-icon{
        margin-right: 30px;
        font-size: 14px;
        width: 35px;
        min-width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
        background-color: #2b2b2b;
        border-radius: 50%;
        @include transition(all 0.5s);
    }

    .logout-text{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex-grow: 1;
        flex-shrink: 1;
        @include transition(all 0.5s);
    }
    &:hover {
        .logout-icon {
            color: $color-red;
        }
        .logout-text{color:#d8d8d8;}
    }
}
.pro-icon-wrapper{border-radius: 50% !important;
    overflow: hidden;}

.pro-sidebar{
    .pro-menu .pro-menu-item > .pro-inner-item:hover{color:$color-blueGreen;}
    a:hover{color:$color-blueGreen;}
    .menu-btn{position: absolute; right: 5px; top:5px; cursor: pointer; padding:5px 10px; background: #1d1d1d; @include transition(all 0.5s);
        &.open-menu{ @include transition(all 0.5s); right:50%; @include transform(translate(50%,0)); top:10px;}
    }
}

.DraftEditor-root{
    min-height: 200px;
    .public-DraftEditor-content{ min-height: 200px;}
}

.top-add-img{margin-right: 30px;}

.folder-icon{color:#ffa500;}
.image-icon{color: #666;}
.doc-icon{color: #666;}

.mediacenter-table{
    a{color:$paragraph-color;}
}

.small-img{
    margin:3px; padding: 5px; display: inline-block; border:1px solid #ddd; border-radius: 3px; overflow: hidden;
}

.item-file{
    background: #fff;
    border:1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    overflow: hidden;
    .image{text-align: center;}
    .text{padding: 20px}
}